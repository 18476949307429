<template>
    <b-modal
      id="table-modal"
      size="md"
      hide-header
      hide-footer
      centered
      no-stacking
      @hidden="onHidden"
      @show="onShow"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p class="title">{{ form?.id ? 'Atualizar' : 'Nova' }} tabela</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <label>Nome</label>
                        <b-form-input v-model="form.name" :state="isValidProp('name')" />
                        <b-form-invalid-feedback :state="isValidProp('name')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <TableTypeSelector 
                        :validated="validated" 
                        v-model="form.type"
                    />
                </b-col>
                <b-col cols="12" v-if="[TABLES.TYPE_PROCEDURE, TABLES.TYPE_OTHER_EXPENSES].includes(form.type)">
                    <b-form-group>
                        <label>Convênio</label>
                        <HealthPlanSelect 
                            :clinicId="clinic.id"
                            v-model="form.clinic_health_plan_ids"
                            multiple
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button 
                        variant="primary" 
                        class="float-right" 
                        :loading="loading"
                        :disabled="loading"
                        @click="save"
                    >
                        Salvar
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
import api from '@tables/api'
import * as TABLES from '@tables/utils/constants'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        TableTypeSelector: () => import('@tables/components/TableTypeSelector'),
        HealthPlanSelect: () => import('@/components/General/HealthPlanSelect'),
    },
    props: {
        table: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            TABLES,
            loading: false,
            validated: false,
            clinic: getCurrentClinic(),
            form: {},
            typeOptions: TABLES.TYPE_OPTIONS.filter(option => TABLES.hiddenTypes.includes(option.value))
        }
    },
    methods: {
        close() {
            this.form = {}
            this.$bvModal.hide('table-modal')
        },
        onHidden() {
            this.form = {}
            this.$emit('hidden')
        },
        onShow() {
            this.form = this.table || {}
        },
        isValidProp(prop) {
            if (!this.validated) return null
            return !!this.form[prop]
        },
        async save() {
            this.validated = true 
            if (!this.form.name || !this.form.type) return
            const payload = {
                ...this.form,
                clinic_id: this.clinic.id
            }
            this.loading = true
            try {
                const { data } = !this.form.id ? await api.createTable(payload) : await api.updateTable(this.form.id, payload)
                this.$toast.success(`Tabela salva com sucesso!`)
                !this.form?.id ? this.$router.push(`/tables/d/${data.id}`) : this.$emit('saved')
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validated = false
                this.loading = false
            }
        }
    },
    watch: {
        async table(value) {
            this.form = value
            this.form.clinic_health_plan_ids = value.clinic_health_plans.map(plan => {
                return plan.clinic_health_plan_id
            })
        },
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
    }
</style>
<style lang="scss">
#table-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 1rem;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
}
</style>